<template>
  <div class="send-science">
    <h2>科技资源列表</h2>
    <div class="header-btn">
      <el-button type="primary" @click="()=>{$router.push('/addScience')}">
        <i class="el-icon-plus"></i> 添加科技资源
      </el-button>
    </div>
    <div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column align="center" prop="date" label="科技资源主图">
          <template slot-scope="scope">
            <img v-if="scope.row.image" :src="scope.row.image" alt width="90px" height="70px" />
            <img v-else src="@/assets/images/暂无图片.png" alt width="90px" height="70px" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="title" label="科技资源标题"></el-table-column>
        <el-table-column align="center" prop label="科技资源类型">
          <template slot-scope="scope">
            <el-tag
              size="mini"
              style="margin:0 1px"
              v-for="(item,i) in scope.row.category"
              :key="i"
            >{{item.name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="editClick(scope.row)" type="primary" size="mini">编辑</el-button>
            <el-button @click="del(scope.row)" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <page class="page" :total="page.total" @currentPageChange="currentPageChange" />
    </div>
  </div>
</template>
<script>
import page from "@/components/PageComponents";
import { success, warning, error } from "@/utils/notification";
export default {
  name: "sendScience",
  components: { page },
  data() {
    return {
      tableData: [], //表格数据
      organ_id: 0, //机构id
      page: { current_page: 0, per_page: 0, total: 0, last_page: 0 },
      nowPage: 1 //当前page
    };
  },
  created() {
    this.organ_id = sessionStorage.organizationId;
    this.resourceList(this.organ_id, 1);
  },
  methods: {
    //科技资源列表
    async resourceList(organ_id, page) {
      const { data: res } = await this.$http.get(this.URL.adminUrl.resource, {
        params: { organ_id, page }
      });
      this.tableData = res.data.data;
      this.tableData.forEach(item => {
        if (item.title.length > 30) {
          item.title = item.title.substring(0, 30) + "······";
        }
      });
      this.page = res.data.meta;
    },
    //编辑单条科技资源
    editClick(data) {
      this.$router.push({
        path: "/addScience",
        query: { id: data.id }
      });
    },
    //分页
    currentPageChange(e) {
      this.nowPage = e;
      this.resourceList(this.organ_id, e);
    },
    //删除
    del(data) {
      this.$confirm("此操作将永久删除该仪器信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$http
          .delete(this.URL.adminUrl.resource + data.id, {
            params: { organ_id: this.organ_id }
          })
          .then(res => {
            success("删除成功");
            this.resourceList(this.organ_id, this.nowPage);
            console.log(res, "删除删除删除");
          });
      });
    }
  }
};
</script>
<style lang="less" scoped>
.send-science {
  .header-btn {
    text-align: right;
    margin: 20px 0;
  }
  .page {
    text-align: center;
    margin-top: 10px;
  }
}
</style>